import './App.css';

function App() {
  return (
    // <div>
    //   Oduko Ositadimma profile Website
    // </div>
    <div className="coming-soon-container">
      <h1>Welcome, and thanks for checking me out</h1>
      <h1>Oduko Ositadimma</h1>

      <p>Hi! my name is Mazi Ositadimma Oduko. Noticed you are trying to check my profile out, well me and my developers are making some changes and would be back soon.</p>
      <p>Stay tuned for something amazing!</p>
    </div>
  );
}

export default App;
